import { getTrackingPageType } from '../tracking/page';

/**
 * Get the list name for a content block
 *
 * @param {HTMLElement} el
 * @param {string[]} suffixParts
 * @returns {string}
 */
export const getContentBlockListName = (el, suffixParts = []) => {
  const pageType = getTrackingPageType();

  const block = el.closest('[data-ga-block-type][data-ga-block-name]');
  const { gaBlockType, gaBlockName } = block.dataset;

  const parts = [pageType, gaBlockType, gaBlockName, ...suffixParts];

  return parts.join('/');
}
