import { getContentBlockListName } from '..';
import { getProductElementData, trackProductElementClick, trackProductListImpressions } from '../../tracking/list';
import { replaceProductCards } from '../../recommendations';

/**
 * Initialise Product Carousel Blocks
 *
 * This function initialises product and collection carousel blocks, depending
 * on the given block selector. It must not be called more than once for each
 * block type.
 */
export const initProductCarouselBlocks = async (blockSelector) => {
  // Add an IntersectionObserver to track impressions when a product
  // carousel block becomes visible in the viewport.
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      // Unobserve, so we only record impressions once per block.
      observer.unobserve(entry.target);

      const list = getContentBlockListName(entry.target);

      const productLinks = entry.target.querySelectorAll('.product-preview__link');
      const items = [];
      for (const [position, productLink] of productLinks.entries()) {
        items.push(getProductElementData(productLink, { position }));
      }

      // Record impressions for every product in the block, even those not
      // yet visible in the carousel.
      trackProductListImpressions({ list, items });
    }
  });

  const blocks = document.querySelectorAll(blockSelector);

  // Load the product cards for each carousel block.
  // This is done in parallel to improve performance.
  await Promise.all([...blocks].map(async (block) => {
    const container = block.querySelector('.carousel--favourites');

    const cards = await replaceProductCards(container, '.product-preview--placeholder');

    if (cards.length === 0) {
      return;
    }

    cards.forEach((card) => {
      card.querySelector('.product-preview__link')?.setAttribute('data-gatype', 'product-card');
    });

    $(container).on('init', () => {
      observer.observe(container);
    });

    const carousel = container.querySelector('.js-carousel--favourites');

    const productLinks = carousel.querySelectorAll('.product-preview__link');

      // Add click event listeners to each product in the block and get the
      // tracking data from the DOM.
    for (const [position, productLink] of productLinks.entries()) {
      productLink.addEventListener('click',
        (e) => trackProductElementClick(e, getContentBlockListName(container), { position })
      );

    }

    // Dispatch an event, which we can listen for in legacy JavaScript code,
    // in order to initialise the carousel UI.
    //
    // See resources/javascript/mv2020/components/carousel.js
    container.dispatchEvent(new CustomEvent('product-carousel-loaded', {
      detail: {
        container,
        carousel,
      },
      bubbles: true,
    }));
  }));
};
