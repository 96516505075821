import { addCacheParams, fixSiteURL } from '../../util/url';

export async function loadProductCard(productCode, params = {}) {
  const url = fixSiteURL('/carousel/card');
  url.searchParams.set('product', productCode);
  url.searchParams.set('ajax', '1');
  addCacheParams(url);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }

  url.searchParams.sort();

  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (response.status !== 200) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (e) {
    throw new Error('Failed to load product card', {
      cause: e,
    });
  }
}

const replaceProductCard = async (placeholder, params) => {
  try {
    const productCode = placeholder.dataset.productCode;
    if (!productCode) {
      throw new Error('No product code found');
    }

    const { html } = await loadProductCard(productCode, params);

    const div = document.createElement('div');
    div.innerHTML = html;
    const card = div.children[0];

    placeholder.replaceWith(card);

    return card;
  } catch (e) {
    placeholder.remove();
    return null;
  }
};

export async function replaceProductCards(container, cardSelector, params = {}) {
  const placeholders = container.querySelectorAll(cardSelector);

  return await Promise.all([...placeholders].map((placeholder) => replaceProductCard(placeholder, params)));
};
